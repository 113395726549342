import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class TextareaInput extends Vue {
  private value = ''

  @Prop({ required: false, type: String })
  readonly initialValue: string

  @Prop({ required: true, type: String })
  readonly label: string

  @Prop({ required: false, type: Boolean, default: false })
  readonly required: boolean

  @Watch('value')
  private onValueChanged (value: string) {
    this.$emit('change', value)
  }

  private created () {
    if (this.initialValue) {
      this.value = this.initialValue
    }
  }
}
