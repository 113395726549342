import { Component, Vue, Prop } from 'vue-property-decorator'

type Option = {
  title: string;
  text: string;
}

export type SwitcherOptions = {
  a: Option;
  b: Option;
}

@Component
export default class Switcher extends Vue {
  private isActive = true

  @Prop({ required: false, type: Object as () => SwitcherOptions })
  readonly options: SwitcherOptions

  private toggleClass (isActive: boolean) {
    this.$emit('switch', isActive)
    this.isActive = isActive
  }
}
